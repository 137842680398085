import React from "react"
import styled from "@emotion/styled"
import { theme } from "../particles/theme"

const StyledFooter = styled.footer`
  color: white;
  background-color: ${theme.colors.theHomeDepot.primary};
  width: 100%;
  padding: 15px;
  text-align: center;
  margin-top: 15px;

  a {
     color: black;
     text-decoration: none;
  }

  p {
     padding: 0px 15px;
  }
`


const Footer = () => {
   return(
      <StyledFooter>
        <p>&copy; Henkel Canada Corporation. All Rights Reserved</p>
          <p>
          <a href="https://www.henkel-northamerica.com/terms-of-use-na?view=content-blank">Terms of Use</a> | <a href="https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank">Privacy Policy</a> | <a href="https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank#analytics">About Ads</a> | <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookies</a>
          </p>
     <p>The Home Depot and Home Depot are registered trademarks of Home Depot International, Inc.</p>
     <p>LePage is a registered trademark of Henkel Canada Corporation.</p>
        <p>Design and hosting by <a href="https://www.buildingonline.com" target="_blank" rel="noreferrer">BuildingOnline</a></p>
      </StyledFooter>
   )
}

export default Footer
